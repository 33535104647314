<template>
  <div style="background: linear-gradient(to top, #FFF, #F8F9FD);height: 97.6%;">
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/iotDataExport' }">自定义查询</el-breadcrumb-item>
          <el-breadcrumb-item>新增规则</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <div style="display:flex;justify-content: space-between;align-items:center;">
          <div class="white-bg-card">
            <div class="form-title-custom">基础信息</div>
            <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item label="规则名称" prop="name">
                    <el-input v-model="form.name" size="small" placeholder="请输入规则名称"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <div class="column-wrapper">
                <span class="column-name">列数名称</span>
                <el-button type="primary" plain size="small" @click="addColumn">+ 新增列</el-button>
              </div>
              <div class="dynamic-column">
                <div class="dynamic-column-item" v-for="(item,index) in columnNameArr" :key="index">
                  <el-input v-model="item.name" size="small" placeholder="请输入列名称" style="width: 100%;"></el-input>
                  <i class="column-item-icon el-icon-remove" style="color: red;" @click="closeColumn(index)"></i>
                </div>
              </div>
              <div class="bottom-btn">
                <el-button type="primary" size="small" @click="$router.push('/iotDataExport')">取消</el-button>
                <el-button type="primary" @click="onSubmit" size="small">提交</el-button>
              </div>
            </el-form>
          </div>
        </div>
        <device-info @loading="loadings" @updateDeviceData="updateDeviceDatas" :columnNameArrs="columnNameArr"></device-info>
      </div>
    </div>
  </div>
</template>

<script>
import deviceInfo from './deviceInfo/add'
import dayjs from "dayjs";
export default {
  components: {
    deviceInfo
  },
  data() {
    return {
      loading: false,
      form: {
        name: '',
        columnName: '',
      },
      columnNameArr: [{name: ''}],
      rules: {
        name: [{ required: true, message: "必填项", trigger: "blur" }],
        columnName: [{ required: true, message: "必填项", trigger: "blur" }],
      },
      deviceData: [],
    };
  },
  methods: {
    dayjs,
    // 新增列
    addColumn() {
      this.columnNameArr.push({name:''})
    },
    // 删除列
    closeColumn(index) {
      if(index==0) return
      this.columnNameArr.splice(index,1)
    },
    loadings(value) {
      this.loading =  value
    },
    updateDeviceDatas(value) {
      this.deviceData = value
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let form
          let columnName = this.columnNameArr.some(v=>!v.name)
          if(columnName) return this.$message.error('请输入列名称')

          if(this.deviceData.length==0) return this.$message.error('请选择设备')
          let equipmentName = this.deviceData.some(v=>!v.equipmentName)
          if(equipmentName) return this.$message.error('请选择设备')

          for(let i=0;i<this.deviceData.length;i++) {
            let attributes = this.deviceData[i].attributes
            for(let j=0;j<attributes.length;j++) {
              let at =  attributes[j]
              if(!at.tag) return this.$message.error('请选择点表')
              // if(!at.describe) return this.$message.error('请输入描述')
              // if(!at.unit) return this.$message.error('请输入单位')
              if(!at.dataHanding) return this.$message.error('请选择统计类别')
              if(!at.index) return this.$message.error('请选择所属列')
            }
          }

          form = {
            customSearchGroupId: this.form.customSearchGroupId,
            ruleName: this.form.name,
            titles: [],
            equipmentBrandCode: this.deviceData[0].equipmentBrandCode,
            equipments: [],
          }

          this.columnNameArr.forEach((v,i)=>{
            form.titles.push({
              title: v.name,
              index: i+1
            })
          })

          this.deviceData.forEach(v=>{
            let tags = []
            v.attributes.forEach(a=>{
              tags.push({
                tag: a.tag,
                describe: a.describe,
                unit: a.unit,
                dataHanding: a.dataHanding,
                index: a.index
              })
            })
            form.equipments.push({
              eid: v.eid,
              // equipmentBrandCode: v.equipmentBrandCode,
              tags: tags
            })
          })
          // console.log(form)
          this.loading = true
          this.$ajax.post('customDataRuleCreate', form).then((res) => {
            this.loading = false
            this.$message.success("成功");
            this.$router.push("/iotDataExport");
          }).catch(err=>{
            this.loading = false
          })
        }
      });
    },
  },
  mounted() {
    if (this.$route.params.customSearchGroupId == null) {
      this.$router.push('/iotDataExport')
    } else {
      this.form.customSearchGroupId = this.$route.params.customSearchGroupId
    }
  },
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading {
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.form-box {
  max-width: 100%;
  margin: 0 auto;
  padding: 0px 0px 50px;
}
.column-wrapper {
  display: flex;
  align-items: center;
  .column-name {
    width: 88px;
    color: #797979;
    text-align: right;
    font-size: 14px;
    line-height: 40px;
    margin-right: 12px;
    &::before {
      content: '*';
      color: #F56C6C;
      margin-right: 4px;
    }
  }
}
.dynamic-column {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  // background: #e6e6e6;
  margin: 0 100px;
  .dynamic-column-item {
    width: 12%;
    display: flex;
    align-items: center;
    margin: 4px 0;
    .column-item-icon {
      display: inline-block;
      margin: 0 10px;
      cursor: pointer;
      font-size: 18px;
    }
    &:nth-child(1) .column-item-icon {
      opacity: .4;
      cursor: not-allowed;
    }
  }
}
.search {
  height: 63px;
  padding: 0 10px;
  display: flex;
  .title-label {
    width: 80px;
    color: #797979;
    text-align: right;
    font-size: 14px;
    line-height: 40px;
    box-sizing: border-box;
    margin-right: 10px;
    &::before {
      content: '*';
      color: #F56C6C;
      margin-right: 4px;
    }
  }
  .select-label {
    font-size: 14px;
    margin-right: 10px;
    color: #767779;
  }
}
/deep/.el-radio-button--small .el-radio-button__inner {
  padding: 10px 22px;
}
</style>